let url = 'https://app.adenin.com/api-7ypLb/';

if (process.env.ASSISTANT_API_URL) {
  url = process.env.ASSISTANT_API_URL;
}

if (url.endsWith('/')) {
  url = url.substring(0, url.length - 1);
}

const urlObj = new URL(url);

export const apiUrl = url;
export const hostUrl = urlObj.origin;
export const tenantId = urlObj.pathname.replace('/api', '').replace('-', '');
export const defaultFetchOpts = {
  mode: 'cors',
  headers: {
    'X-Requested-By': 'Digital Assistant Client',
  },
};

const config = {
  apiUrl,
  hostUrl,
  tenantId,
  defaultFetchOpts,
  useTokenAuth: false, // should the SPA use regular session cookie or PKCE oauth flow
  oauthClientId: '', // client ID for the PKCE flow from host
  oidcProvider: null, // which (if any) OIDC provider name to use for login
  boardWidthScale: 2, // when != 1 all card widths are multiplied by this scale factor
  boardHeightScale: 0.25, // when != 1 all card heights are multiplied by this scale factor
  boardDisableBackground: true, // disables the placeholder grid units on board background
};

if (process.env.OIDC_PROVIDER) {
  config.oidcProvider = process.env.OIDC_PROVIDER;
}

export default config;
